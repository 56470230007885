import React, { Component } from "react";

class Paragraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  style = {
    header: {
      fontSize: this.props.headerFontSize,
      color: "white",
      fontFamily: "Quark-Bold",
      margin: "1em 0",
    },
    param: {
      color: "white",
      fontSize: this.props.paragraphFontSize,
      fontFamily: "Quark-Light",
      paddingBottom: "1em",
    },
    container: {
      padding: "5vw",
      position: "absolute",
      marginBottom: "1em",
    },
    innerContainer: {
      position: "absolute",
      width: "100%",
    },
    list: {
      marginLeft: "2em",
      marginBottom: "0.7em",
    },
  };

  getValueFromObject(object) {
    return Object.values(object);
  }

  render() {
    return (
      <div style={this.style.container}>
        <h1 style={this.style.header} id={"header_sec_" + this.props.id}>
          {this.props.header}
        </h1>
        <div style={this.style.innerContainer}>
          {typeof this.props.param1 === "object" ? (
            <ol style={this.style.param} id={"param_1_sec_" + this.props.id}>
              {this.getValueFromObject(this.props.param1).map((element) => (
                <li style={this.style.list} key={element}>
                  {element}
                </li>
              ))}
            </ol>
          ) : (
            <p style={this.style.param} id={"param_1_sec_" + this.props.id}>
              {this.props.param1}
            </p>
          )}
        </div>
        <div style={this.style.innerContainer}>
          {typeof this.props.param2 === "object" ? (
            <ol
              style={this.style.param}
              id={"param_2_sec_" + this.props.id}
              start={typeof this.props.param1 === "object" ? 4 : 1}
            >
              {this.getValueFromObject(this.props.param2).map((element) => (
                <li style={this.style.list} key={element}>
                  {element}
                </li>
              ))}
            </ol>
          ) : (
            <p style={this.style.param} id={"param_2_sec_" + this.props.id}>
              {this.props.param2}
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default Paragraph;
