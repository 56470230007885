import React from "react";
import { useMediaQuery } from "react-responsive";
import "./css/App.css";
import LandingPagePC from "./components/LandingPagePC";
import LandingPageMobile from "./components/LandingPageMobile";
import LandingPageTablet from "./components/LandingPageTablet";

function App() {
  const isMobileOrPC = useMediaQuery({
    minDeviceWidth: "1224px",
  });

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: "720px",
  });

  return (
    <div className="App">
      {isMobileOrPC ? (
        <React.Fragment>
          <LandingPagePC />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {isTabletOrMobile ? <LandingPageMobile /> : <LandingPageTablet />}
        </React.Fragment>
      )}
    </div>
  );
}

export default App;
