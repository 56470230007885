import React, { Component } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import "../css/LandingPageMobile.css";
import SwipableCard from "./SwipableCard";
import Info from "../json/member.json";

class QueueId {
  constructor(id) {
    this.id = id;
    this.nextId = undefined;
    this.prevId = undefined;
    this.draggable = undefined;
  }

  setNextId = (id) => {
    this.nextId = id;
  };

  setPrevId = (id) => {
    this.prevId = id;
  };

  getNextId = () => {
    return this.nextId;
  };

  getPrevId = () => {
    return this.prevId;
  };

  getId = () => {
    return this.id;
  };

  setDraggable = (draggable) => {
    this.draggable = draggable;
  };

  getDraggable = () => {
    return this.draggable;
  };
}

class Queue {
  constructor() {
    this.head = undefined;
    this.current = undefined;
    this.tail = undefined;
  }

  push = (id) => {
    const newId = new QueueId(id);
    if (!this.head) {
      this.head = newId;
      this.tail = newId;
      this.current = newId;
    } else {
      this.tail.setNextId(newId);
      newId.setPrevId(this.tail);
      this.tail = newId;
    }
  };

  setDraggable = (id, draggable) => {
    let tmp = this.tail;
    while (tmp) {
      if (tmp.getId() === id) {
        tmp.setDraggable(draggable);
      }
      tmp = tmp.getPrevId();
    }
  };

  getDraggable = (id) => {
    let tmp = this.tail;
    while (tmp) {
      if (tmp.getId() === id) {
        return tmp.getDraggable();
      }
      tmp = tmp.getPrevId();
    }
  };

  lookUp = (id) => {
    let tmp = this.head;
    while (tmp) {
      if (tmp.getId() === id) {
        return tmp;
      }
      tmp = tmp.getNextId();
    }
  };

  getArray = () => {
    const array = [];
    let tmp = this.head;
    while (tmp) {
      array.push(tmp);
      tmp = tmp.getNextId();
    }
    return array;
  };
}

class LandingPageMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    gsap.registerPlugin(Draggable);
    this.doubleLinkedState = new Queue();

    this.refStates = [];
    Info.forEach((element, index) => {
      this.doubleLinkedState.push("card" + index);
      this.refStates.push(React.createRef());
    });
    console.log(this.refStates);
  }

  tmpState = {};

  componentDidMount = () => {
    console.log(this.refStates);
    const setObjState = (id) => {
      if (
        (!this.doubleLinkedState.lookUp(id).getPrevId() ||
          this.tmpState[id] !== 1) &&
        (this.tmpState[id] !== 3 ||
          !this.doubleLinkedState.lookUp(id).getNextId())
      ) {
        this.tmpState = {};
        return;
      }

      this.setState({
        [id]: this.tmpState[id],
      });

      const returnValue = this.state[id];

      this.doubleLinkedState.getDraggable(id).disable();

      this.doubleLinkedState
        .getArray()
        .filter(
          (filter) =>
            filter.getId() !== id &&
            this.state[filter.getId()] !== 3 &&
            this.state[filter.getId()] !== 4
        )
        .forEach((key) => {
          const elementId = key.getId();
          let tmp;
          if (returnValue === 3) {
            if (this.state[elementId] <= 2) {
              tmp = this.state[elementId] - 1;
              if (
                this.state[elementId] === 2 &&
                this.doubleLinkedState.lookUp(elementId).getNextId()
              ) {
                this.setState({
                  [this.doubleLinkedState
                    .lookUp(elementId)
                    .getNextId()
                    .getId()]: 2,
                });
              }
            }
          } else if (returnValue === 1) {
            if (this.state[elementId] < 2) {
              tmp = this.state[elementId] + 1;
            } else {
              tmp = 4;
            }
          } else {
            this.tmpState = {};
          }

          this.setState({
            [elementId]: tmp,
          });
        });

      if (returnValue === 3) {
        this.doubleLinkedState.lookUp(id).getNextId().getDraggable().enable();
      } else if (returnValue === 1) {
        this.doubleLinkedState.lookUp(id).getPrevId().getDraggable().enable();
        this.setState({
          [this.doubleLinkedState.lookUp(id).getPrevId().getId()]: 0,
        });
      }
    };

    const setTempState = (id, value) => {
      this.tmpState[id] = value;
    };

    let counter = 0;
    const array = this.doubleLinkedState.getArray();
    array.forEach((element, index) => {
      this.setState({
        [element.getId()]: counter <= 2 ? counter : 4,
      });

      const draggable = new Draggable("#card" + index, {
        type: "y",
        bounds: "#card" + index + "c",
        edgeResistance: "0.5",
        minimumMovement: 10,
        // allowEventDefault: true,
        allowNativeTouchScrolling: false,
        onClick: function (ref) {
          // console.log("Clicked!");
          // ref.click();
        },
        onClickParams: [this.refStates[index].current],
        onDrag: function () {
          if (this.y <= -25) {
            setTempState(this.target.id, 3);
          } else if (this.y >= 25) {
            setTempState(this.target.id, 1);
          } else {
            setTempState(this.target.id, 0);
          }
        },
        onDragEnd: function () {
          setObjState(this.target.id);
        },
      });

      if (index !== 0) {
        draggable.disable();
      }
      this.doubleLinkedState.setDraggable(element.getId(), draggable);
      counter++;
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page">
          {Info.map((element, index, info) => (
            <SwipableCard
              key={index}
              src={process.env.PUBLIC_URL + element.path}
              info={element}
              pos={this.state["card" + index]}
              zIndex={info.length - index}
              divId={"card" + index}
              refEntity={this.refStates[index]}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default LandingPageMobile;
