import React, { Component } from "react";
// import { gsap } from "gsap";

class SwipableCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: false,
    };
  }

  style = {
    swipableContainer: [
      {
        width: "80vw",
        // height: "fit-content",
        position: "absolute",
        top: "20%",
        left: "7%",
        // transform: "translateY(-50%)",
        transition: "transform ease 500ms",
        zIndex: this.props.zIndex,
      },
      {
        width: "80vw",
        // height: "fit-content",
        position: "absolute",
        top: "20%",
        left: "7%",
        transform: "translate(10px, 10px)",
        transition: "transform ease 500ms",
        zIndex: this.props.zIndex,
      },
      {
        width: "80vw",
        // height: "fit-content",
        position: "absolute",
        top: "20%",
        left: "7%",
        transform: "translate(20px, 20px)",
        transition: "transform ease 500ms",
        zIndex: this.props.zIndex,
      },
      {
        width: "80vw",
        // height: "fit-content",
        position: "absolute",
        top: "20%",
        left: "7%",
        transform: "translateY(-100vh)",
        transition: "transform ease 500ms",
        zIndex: this.props.zIndex,
      },
      {
        width: "80vw",
        // height: "fit-content",
        position: "absolute",
        top: "20%",
        left: "7%",
        transform: "translateY(100vh)",
        transition: "transform ease 500ms",
        zIndex: this.props.zIndex,
      },
    ],
    cardContainer: {
      width: "100%",
      borderRadius: "10px",
      position: "relative",
      // top: "10%",
      // left: "7%",
      boxShadow: "10px 10px 5px #0001",
    },
    cardFrame: {
      width: "100%",
      height: "100%",
      borderRadius: "inherit",
      position: "relative",
      // backgroundColor: "black",
      overflow: "hidden",
    },
    cardContent: {
      backgroundColor: "white",
      borderRadius: "inherit",
      width: "100%",
      height: "100%",
      padding: "10px",
    },
    cardImg: {
      width: "100%",
      // height: "fit-content",
      borderRadius: "10px",
    },
    paragraph: {
      fontFamily: "Quark-Light",
      textAlign: "right",
      fontSize: "9vw",
      margin: "0.5em",
    },
    iconGroup: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "60%",
      left: "10px",
    },
    icon: {
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#0A84FF",
      transform: "translateX(-50%)",
      color: "#E0E5EC",
      boxShadow: "10px 10px 5px #0001",
      transition: "width ease 300ms",
    },
    expandedIcon: {
      borderRadius: "25px",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#0A84FF",
      transform: "translateX(-10%)",
      color: "#E0E5EC",
      boxShadow: "10px 10px 5px #0001",
      padding: "0 1em",
      transition: "width ease 300ms",
    },
    nameIcon: {
      fontFamily: "Quark-Bold",
      fontSize: "32px",
      color: "black",
      position: "absolute",
      top: "46%",
      right: "-15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100px",
      height: "100px",
      transform: "translateY(-50%)",
      backgroundColor: "white",
      borderRadius: "50%",
    },
    expandOnHoverParagraph: {
      opacity: "1",
      transition: "opacity ease 300ms",
      fontFamily: "Quark-Light",
      fontSize: "22px",
      color: "white",
      marginLeft: "1em",
    },
    hiddenOnIdleParagraph: {
      display: "none",
      transition: "opacity ease 300ms",
      opacity: "0",
      fontFamily: "Quark-Light",
      fontSize: "22px",
      color: "white",
    },
  };

  onClick = () => {
    this.setState({
      click: !this.state.click,
    });
  };

  render() {
    return (
      <div
        style={this.style.swipableContainer[this.props.pos]}
        id={this.props.divId + "c"}
        onClick={this.onClick}
        ref={this.props.refEntity}
      >
        <div style={this.style.cardContainer} id={this.props.divId}>
          <div style={this.style.cardFrame}>
            <div style={this.style.cardContent}>
              <img
                style={this.style.cardImg}
                src={this.props.src}
                alt={this.props.info.alt}
              />
              <span style={this.style.nameIcon}>{this.props.info.alt}</span>
              <p style={this.style.paragraph}>
                {this.props.info.tName} {this.props.info.lName}
              </p>
              <p style={this.style.paragraph}>
                น้องรหัส {this.props.info.junior}/{this.props.info.junior}
              </p>
            </div>
          </div>
          <div style={this.style.iconGroup}>
            <span
              style={
                this.state.click ? this.style.expandedIcon : this.style.icon
              }
            >
              <i className="fab fa-facebook-f"></i>
              <p
                style={
                  this.state.click
                    ? this.style.expandOnHoverParagraph
                    : this.style.hiddenOnIdleParagraph
                }
              >
                {this.props.info.fName}
              </p>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default SwipableCard;
