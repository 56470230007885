import React, { Component } from 'react';
import FloatingCard from './FloatingCard';

class FloatingCardGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    style = {
        container: {
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: this.props.backgroundColor,
            transition: "transform ease 300ms",
            position: "absolute"
        },
        content: {
            display: "flex",
            position: "relative",
        }
    }

    render() {
        return (
            <div style={this.style.container} id={this.props.divId}>
                <div style={this.style.content}>
                    {this.props.info.map((element, index) => <FloatingCard key={index} src={element.path} info={element} divId={this.props.divId + "_" + index}></FloatingCard>)}
                </div>    
            </div>    
        );
    }
}

export default FloatingCardGroup;