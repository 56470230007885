import React, { Component } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import "../css/LandingPagePC.css";
import Window from "./Window";
import NavBar from "./nav-bar/NavBar";
import Page from "./Page";
import SlideParagraph from "../json/slide_paragraph.json";
import Theater from "./Theater";
import Paragraph from "./Paragraph";
import hods from "../images/hods.jpg";
import DIIOpening from "../images/DII_opening.jpg";
import infoGraph1 from "../images/info_graph_1.png";
// import infograph2 from "../images/info_graph_2.png";
import TeamHeader from "./TeamHeader";
import Member from "../json/member.json";
import FloatingCardGroup from "./FloatingCardGroup";
import Opening from "../json/opening.json";
import Fountain from "./Fountain";

class LandingPagePC extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
    ScrollTrigger.defaults({
      toggleActions: "play pause reverse reset",
      // markers: true,
    });
    this.memberChunks = [];
    Member.forEach((element) => {
      if (element.index % 3 === 0) {
        this.memberChunks.push(
          Member.slice(
            parseInt(element.index) - 3,
            parseInt(element.index) < Member.length
              ? parseInt(element.index)
              : Member.length
          )
        );
      }
    });
  }

  slides = [DIIOpening, infoGraph1, hods, hods];

  componentDidMount = () => {
    const tl = new gsap.timeline({
      scrollTrigger: {
        trigger: "#home",
        start: "top top",
        end: "center top",
        id: "home",
        scrub: true,
        pin: "#home",
        snap: {
          snapTo: "labels",
          duration: 1,
          delay: 0.2,
          ease: "expo.out",
        },
        onEnterBack: () => {
          gsap.to(window, {
            scrollTo: {
              y: 0,
              duration: 2,
            },
          });
        },
      },
    });

    tl.addLabel("Label1")
      .to("#Logo_1_", {
        x: -120,
        y: 40,
        duration: 1,
        ease: "expo.out",
      })
      .addLabel("Label2");

    const tl2 = new gsap.timeline({
      scrollTrigger: {
        trigger: "#about",
        scrub: true,
        end: "bottom -300%",
        pin: "#about",
        id: "about",
        snap: {
          snapTo: "labels",
          duration: 1.2,
          delay: 0.15,
          ease: "power1.inOut",
        },
      },
    });

    SlideParagraph.forEach((element, index) => {
      tl2.add(this.newNonReverseTimeline(index));
      tl2.addLabel("Label" + index);
      if (index !== SlideParagraph.length - 1) {
        tl2.add(this.newReverseTimeline(index));
      }
    });

    const selectorTl = new gsap.timeline({
      scrollTrigger: {
        trigger: "#selector",
        end: "95% -400%",
        id: "pop-up",
        scrub: true,
        snap: {
          snapTo: "labels",
          duration: 1,
          delay: 0.15,
          ease: "power1.inOut",
        },
        pin: "#selector",
        onLeaveBack: () => {
          gsap.to(window, {
            scrollTo: "#about",
            duration: 1.5,
          });
        },
      },
    });

    this.memberChunks.forEach((element, index) => {
      selectorTl.add(this.newCardTimeline(index));
      selectorTl.addLabel("Label" + index);
      if (index !== this.memberChunks.length - 1) {
        selectorTl.add(this.newReverseCardTimeline(index));
      }
    });

    const contactTl = gsap.timeline({
      scrollTrigger: {
        trigger: "#contact",
        id: "contact-timeline",
        start: "70% bottom",
        end: "bottom 105%",
        toggleActions: "play complete none none",
        // once: true
      },
    });

    contactTl
      .from(".neumorphism__button", {
        boxShadow: "0 0 0 #e0e5ec",
        color: "#e0e5ec",
        duration: 0.3,
      })
      .from(
        "#Logo_2_",
        {
          y: "-50%",
          duration: 1,
        },
        "-=0.1"
      );
  };

  newReverseTimeline = (section) => {
    const tl = new gsap.timeline();

    tl.to("#header_sec_" + section, {
      opacity: "0",
      y: "100px",
      duration: 3,
    })
      .to("#param_1_sec_" + section, {
        opacity: "0",
        x: 1000,
        duration: 10,
      })
      .to(
        "#param_2_sec_" + section,
        {
          opacity: "0",
          x: 1000,
          duration: 10,
        },
        "-=7"
      )
      .to(
        "#Spotlight_sec_" + section,
        {
          opacity: "0",
          duration: 10,
          ease: "bounce.out",
        },
        "-=23"
      )
      .to(
        "#Lightbeam_sec_" + section,
        {
          opacity: "0",
          duration: 10,
          ease: "bounce.out",
        },
        "-=23"
      )
      .to(
        "#pic_sec_" + section,
        {
          opacity: "0",
          duration: 10,
          ease: "bounce.out",
        },
        "-=23"
      );

    return tl;
  };

  newNonReverseTimeline = (section) => {
    const tl = new gsap.timeline();

    tl.from("#Spotlight_sec_" + section, {
      opacity: "0",
      duration: 10,
      ease: "bounce.out",
    })
      .from(
        "#Lightbeam_sec_" + section,
        {
          opacity: "0",
          duration: 10,
          ease: "bounce.out",
        },
        "-=10"
      )
      .from(
        "#pic_sec_" + section,
        {
          opacity: 0,
          duration: 10,
          ease: "bounce.out",
        },
        "-=10"
      )
      .from("#header_sec_" + section, {
        opacity: "0",
        y: "-100px",
        duration: 3,
      })
      .from("#param_1_sec_" + section, {
        opacity: "0",
        x: 1000,
        duration: 10,
      })
      .from("#param_2_sec_" + section, {
        opacity: "0",
        x: 1000,
        duration: 10,
      });

    return tl;
  };

  newCardTimeline = (section) => {
    const tl = new gsap.timeline();

    tl.from("#year_62_" + section, {
      y: "100vh",
      duration: 20,
    });
    this.memberChunks[section].forEach((element, index) =>
      tl.from(
        "#year_62_" + section + "_" + index,
        {
          y: "100vh",
          duration: 20,
        },
        "-=10"
      )
    );

    return tl;
  };

  newReverseCardTimeline = (section) => {
    const tl = new gsap.timeline();

    tl.addLabel("Label");
    this.memberChunks[section].forEach((element, index) =>
      tl.to(
        "#year_62_" + section + "_" + index,
        {
          y: "-100vh",
          duration: 10,
        },
        "-=5"
      )
    );
    tl.to(
      "#year_62_" + section,
      {
        y: "-100vh",
        duration: 10,
        delay: 5,
      },
      "Label"
    );

    return tl;
  };

  callToAction() {
    gsap.to(window, {
      scrollTo: {
        y: "#about",
        offsetY: -10,
      },
      ease: "sine.in",
      duration: 3,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Page bgColor={"#EFB889"} padding={"10vw 5vw"} id="home">
          <div className="circular__clip__path"></div>
          <div className="container">
            <div className="primary__column">
              <h1 className="header">{Opening.header}</h1>
              <p className="param">{Opening.opening}</p>
              <button className="trigger" onClick={this.callToAction}>
                {Opening.callToAction}
              </button>
            </div>
            <div className="secondary__column">
              <Window />
            </div>
          </div>
        </Page>
        <Page bgColor={"#313131"} id="about">
          <div className="container">
            <div className="projection">
              {this.slides.map((element, index) => (
                <Theater key={index} src={element} alt={index} id={index} />
              ))}
            </div>
            <div className="paragraph__container__parent">
              {SlideParagraph.map((element, index) => (
                <Paragraph
                  key={index}
                  header={element.header}
                  param1={element.param1}
                  param2={element.param2}
                  id={index}
                  headerFontSize={"56px"}
                  paragraphFontSize={"26px"}
                />
              ))}
            </div>
          </div>
        </Page>
        <Page bgColor={"#ffce4c"} id="selector">
          <TeamHeader />
          <p className="team__header">พี่น้องสาขา DII</p>
          {this.memberChunks.map((element, index) => (
            <FloatingCardGroup
              key={index}
              info={element}
              divId={"year_62_" + index}
            />
          ))}
        </Page>
        <Page bgColor={"#e0e5ec"} id="contact" height={"60vh"}>
          <div className="container">
            <div className="primary__column" style={{ padding: "5vw 10vw" }}>
              <Fountain />
            </div>
            <div className="paragraph__container__parent contact">
              <div className="neumorphism__button__group">
                <button
                  className="neumorphism__button"
                  id="facebook__neumorph__button"
                >
                  <i class="fab fa-facebook" aria-hidden="true"></i>
                </button>
                <button
                  className="neumorphism__button"
                  id="youtube__neumorph__button"
                >
                  <i class="fab fa-youtube" aria-hidden="true"></i>
                </button>
                <button
                  className="neumorphism__button"
                  id="instagram__neumorph__button"
                >
                  <i class="fab fa-instagram" aria-hidden="true"></i>
                </button>
                <button
                  className="neumorphism__button"
                  id="twitter__neumorph__button"
                >
                  <i class="fab fa-twitter" aria-hidden="true"></i>
                </button>
                <button
                  className="neumorphism__button"
                  id="email__neumorph__button"
                >
                  <i class="fas fa-envelope" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </Page>
      </React.Fragment>
    );
  }
}

export default LandingPagePC;
