import React, { Component } from "react";
import Projector from "./Projector";

class Theater extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  style = {
    container: {
      padding: "5vh 0 5vh 10vh",
      maxHeight: "100%",
      position: "absolute",
    },
    projected: {
      position: "absolute",
      width: "41%",
      height: "33%",
      transform: "skewY(150deg)",
      zIndex: "-1",
      top: "25%",
      left: "20%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    svg: {
      margin: "5vh",
    },
  };

  render() {
    return (
      <div style={this.style.container}>
        <div style={this.style.projected}>
          <img
            src={this.props.src}
            alt={this.props.alt}
            id={"pic_sec_" + this.props.id}
          ></img>
        </div>
        <Projector section={this.props.id} svg={this.style.svg} />
      </div>
    );
  }
}

export default Theater;

