import React, { Component } from 'react';
import { gsap } from 'gsap';
import '../../css/NavBar.css';
import logo from '../../images/DII.png';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false
        };
    }

    style = {
        navBody: {
            position: "fixed",
            width: "100vw",
            top: "0",
            zIndex: "100"
        },
        background: {
            position: "absolute",
            backgroundColor: "#313131",
            opacity: "0",
            top: "0",
            left: "0",
            width: "0",
            height: "100vh",
            zIndex: "99",
            transition: "opacity ease 300ms"
        },
        backgroundShown: {
            position: "absolute",
            backgroundColor: "#313131",
            opacity: "0.8",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "99",
            transition: "opacity ease 300ms 300ms"
        },
        logo: {
            width: "10vh",
            height: "fit-content",
            margin: "10px",
            position: "absolute",
            transition: "all ease 300ms",
            top: "0",
            left: "0",
            zIndex: "101"
        },
        logoExpanded: {
            width: "10vh",
            height: "fit-content",
            margin: "10px",
            position: "absolute",
            transform: "translate(200px, 200px) scale(4)", 
            transition: "all ease 300ms",
            top: "0",
            left: "0",
            zIndex: "101"
        },
        circle: {
            position: "absolute",
            backgroundColor: "#f7941d",
            clipPath: "circle(0% at 0% 0%)",
            transition: "all ease 500ms",
            width: "100vw",
            height: "100vh",
            top: "0",
            left: "0",
            zIndex: "100",
            padding: "20vh 0 0 35vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start"
        },
        circleExpanded: {
            position: "absolute",
            backgroundColor: "#f7941d",
            clipPath: "circle(100% at 0% 0%)",
            transition: "all ease 500ms",
            width: "100vw",
            height: "100vh",
            top: "0",
            left: "0",
            zIndex: "100",
            padding: "20vh 0 0 35vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start"
        },
        link: {
            border: "none",
            backgroundColor: "transparent",
            fontFamily: "Quark-Bold",
            fontSize: "72px",
            color: "white",
            padding: "0.25em"
        }
    };
    
    
    componentDidMount = () => {
        this.link = [
            gsap.timeline({
                paused: true
            }),
            gsap.timeline({
                paused: true
            }),
            gsap.timeline({
                paused: true
            }),
            gsap.timeline({
                paused: true
            }),
        ];

        this.reverseLink = [
            gsap.timeline({
                paused: true
            }),
            gsap.timeline({
                paused: true
            }),
            gsap.timeline({
                paused: true
            }),
            gsap.timeline({
                paused: true
            }),
        ];
        
        this.link.forEach((element, index) => element.add(this.mountHoverEffect(index)));

        this.reverseLink.forEach((element, index) => element.add(this.mountReverseHoverEffect(index)));
    }

    mountHoverEffect = (id) => {
        const tl = gsap.timeline();

        tl.to('#link' + id, {
            x: "20px",
            duration: 0.3
        }).to('#link' + id, {
            skewX: "15deg",
            duration: 0.1
        }, "-=0.3").to('#link' + id, {
            skewX: "0",
            duration: 0.1
        }, "-=0.1")

        return tl;
    }

    mountReverseHoverEffect = (id) => {
        const tl = gsap.timeline();

        tl.to('#link' + id, {
            x: "0",
            duration: 0.3
        }).to('#link' + id, {
            skewX: "-15deg",
            duration: 0.1
        }, "-=0.3").to('#link' + id, {
            skewX: "0",
            duration: 0.1
        }, "-=0.1")

        return tl;
    }

    onClick = () => {
        this.setState({
            clicked: !this.state.clicked
        });
    }

    onHoverEnter = (e) => {
        switch (e.target.id)
        {
            case 'link0':
                this.link[0].play(0)
                break;
            case 'link1':
                this.link[1].play(0)
                break;
            case 'link2':
                this.link[2].play(0)
                break;
            case 'link3':
                this.link[3].play(0)
                break;
            default: 
        }
    }

    onHoverLeave = (e) => {
        switch (e.target.id)
        {
            case 'link0':
                this.reverseLink[0].play(0)
                break;
            case 'link1':
                this.reverseLink[1].play(0)
                break;
            case 'link2':
                this.reverseLink[2].play(0)
                break;
            case 'link3':
                this.reverseLink[3].play(0)
                break;
            default: 
        }
    }

    onMouseClick = (e) => {
        gsap.to(window, {
            scrollTo: {
                y: '#' + e.target.value,
                offsetY: -10
            },
            duration: 1,
            ease: "sine.in"
        });
    }

    render() {
        return (
        <div style={this.style.navBody}>
            <div style={this.state.clicked? this.style.backgroundShown : this.style.background} onClick={this.onClick}>
            </div>
            <div style={this.state.clicked? this.style.circleExpanded : this.style.circle} onClick={this.onClick}>
                <button style={this.style.link} id="link0" onMouseEnter={((e) => this.onHoverEnter(e))} onMouseLeave={((e) => this.onHoverLeave(e))} onClick={((e) => this.onMouseClick(e))} value="home">Home</button>
                <button style={this.style.link} id="link1" onMouseEnter={((e) => this.onHoverEnter(e))} onMouseLeave={((e) => this.onHoverLeave(e))} onClick={((e) => this.onMouseClick(e))} value="about">Intro</button>
                <button style={this.style.link} id="link2" onMouseEnter={((e) => this.onHoverEnter(e))} onMouseLeave={((e) => this.onHoverLeave(e))} onClick={((e) => this.onMouseClick(e))} value="selector">Members</button>
                <button style={this.style.link} id="link3" onMouseEnter={((e) => this.onHoverEnter(e))} onMouseLeave={((e) => this.onHoverLeave(e))} onClick={((e) => this.onMouseClick(e))} value="contact">Contact us</button>
            </div>
            <img src={logo} alt="DII" style={this.state.clicked? this.style.logoExpanded : this.style.logo} onClick={this.onClick} />
        </div>
        );
    }
}

export default NavBar;