import React , { Component} from 'react';

class Projector extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    style = {
        st0: {
            opacity: "0.91",
            fill: "#FFFF00"
        },
        st1: {fill: "#1A1A1A"},
        st2: {fill: "#333333"},
        st3: {fill: "#4D4D4D"},
        st4: {fill: "#2E3192"},
        st5: {fill: "#3535FF"},
        st6: {fill: "#FFFFFF"},
        st7: {fill: "#FF0000"},
        st8: {fill: "#00FF00"},
        st9: {
            opacity: "0.4",
            fill: "url(#Lightbeam_1_sec_" + this.props.section + ")"
        }
    };

    render() {
        return (
            <React.Fragment>
                <svg version="1.1" id={"Layer_1_sec_" + this.props.section} style={this.props.svg} x="0px" y="0px" viewBox="0 0 439 476">
                <path id={"Spotlight_sec_" + this.props.section} style={this.style.st0} d="M351.51,300.5l0.04,34.14c0,4.42-3.09,9.79-6.92,12l-29.2,16.86c-3.83,2.21-6.93,0.42-6.93-4
                    v-34c0-4.42,3.1-9.8,6.92-12.02l29.17-16.96C348.4,294.3,351.5,296.08,351.51,300.5z"/>
                    <g id={"Projector_sec_" + this.props.section}>
                        <polygon style={this.style.st1} points="409.49,425.49 409.5,425.5 380.5,375.5 380.42,375.46 380.42,375.46 380.42,375.45 366.5,367.5 
                            395.5,417.5 404.17,422.46 406.99,427.09 	"/>
                        <polyline points="377.99,377.1 406.99,427.1 392.99,419.1 363.99,369.1 377.99,377.1 	"/>
                        <polygon style={this.style.st2} points="365.5,367.5 351.5,375.5 322.53,425.44 322.53,425.44 322.53,425.44 322.5,425.5 322.57,425.46 
                            324.9,426.94 328.14,422.28 336.5,417.5 	"/>
                        <polyline style={this.style.st1} points="324.86,427 353.86,377 367.86,369 338.86,419 324.86,427 	"/>
                        <polyline style={this.style.st2} points="337,467 341.05,464.65 369.99,381.62 366,384 337,467 	"/>
                        <polygon style={this.style.st3} points="351.56,375.83 323,459 337,467 366,384 	"/>
                        <polygon style={this.style.st2} points="395,467 391.25,465.06 362.34,382.06 366.09,383.95 	"/>
                        <polygon style={this.style.st3} points="366,384 395,467 409,459 380,376 	"/>
                        <path style={this.style.st4} d="M433.91,392.5L433.91,392.5l0.03-16.67l-34.77-5.48l-26.27-15.32c-3.82-2.23-10.01-2.23-13.83-0.01l-12.2,7.09
                            l-19.83-3.13L327,376l0.05,0.03c0.01,1.45,0.96,2.89,2.86,4l58.18,33.94c1.87,1.09,4.31,1.64,6.76,1.66l0.01,0.01l0.01-0.01
                            c2.55,0.02,5.1-0.52,7.05-1.66l29.17-16.96C433.21,395.78,434.15,394.12,433.91,392.5z"/>
                        <path style={this.style.st5} d="M359.08,338.02l-29.17,16.96c-3.82,2.22-3.82,5.83-0.01,8.05l58.18,33.94c3.82,2.23,10.01,2.23,13.83,0.01
                            l29.17-16.96c3.82-2.22,3.82-5.83,0.01-8.05l-58.18-33.94C369.09,335.8,362.9,335.8,359.08,338.02z"/>
                        <path style={this.style.st4} d="M419.46,334.16l-92.4-8.33l0.01,33.34l0,0c-0.02,1.47,0.94,2.94,2.86,4.06l43.68,25.45
                            c2.03,1.18,4.72,1.72,7.38,1.64l0.03,0.02l0.04-0.02c2.32-0.07,4.61-0.6,6.38-1.63l29.17-16.96c1.99-1.16,2.93-2.69,2.85-4.21
                            l0.02-0.01V334.16z"/>
                        <path style={this.style.st5} d="M329.92,321.98l29.17-16.96c3.82-2.22,10.01-2.22,13.83,0.01l43.68,25.45c3.82,2.22,3.82,5.83,0,8.05
                            l-29.17,16.96c-3.82,2.22-10.01,2.22-13.83-0.01l-43.68-25.45C326.09,327.8,326.1,324.2,329.92,321.98z"/>
                        <path style={this.style.st1} d="M360.76,387.19l-0.03-23.34c0-3.02-2.12-6.76-4.73-8.35l-19.92-12.16c-1.11-0.67-2.12-0.85-2.92-0.61l-0.01,0
                            l-0.01,0.01c-0.31,0.1-0.58,0.26-0.82,0.47l-1.32,0.76l0.55,0.52c-0.12,0.41-0.19,0.89-0.19,1.43v23.22c0,3.02,2.12,6.76,4.73,8.37
                            l19.94,12.25c0.51,0.31,1,0.52,1.46,0.63l0.19,0.87l1.65-1.03c0.06-0.03,0.12-0.07,0.18-0.11l0.11-0.07l0,0
                            C360.34,389.51,360.76,388.54,360.76,387.19z"/>
                        <path style={this.style.st2} d="M358.97,364.93l0.03,23.34c0,3.02-2.11,4.17-4.73,2.56l-19.94-12.25c-2.61-1.6-4.73-5.35-4.73-8.37V347
                            c0-3.02,2.11-4.17,4.72-2.58l19.92,12.16C356.85,358.17,358.97,361.91,358.97,364.93z"/>
                        
                            <ellipse transform="matrix(0.9927 -0.121 0.121 0.9927 -44.4922 50.7796)" style={this.style.st6} cx="395.93" cy="391.79" rx="5.49" ry="3.11"/>
                        <path style={this.style.st2} d="M405.61,350.56l-0.01,9.05c0,1.17,0.82,1.62,1.83,0.99l7.73-4.75c1.01-0.62,1.84-2.08,1.84-3.25v-9.01
                            c0-1.17-0.82-1.62-1.83-1l-7.73,4.72C406.43,347.93,405.61,349.38,405.61,350.56z"/>
                        
                            <ellipse transform="matrix(0.7992 -0.6011 0.6011 0.7992 -149.4456 274.6441)" style={this.style.st7} cx="336.3" cy="360.98" rx="2.74" ry="4.84"/>
                        
                            <ellipse transform="matrix(0.7992 -0.6011 0.6011 0.7992 -151.1351 281.8904)" style={this.style.st8} cx="346.3" cy="367.13" rx="2.74" ry="4.84"/>
                    </g>
                    <linearGradient id={"Lightbeam_1_sec_" + this.props.section} gradientUnits="userSpaceOnUse" x1="65.3039" y1="112.659" x2="337.4209" y2="327.3874">
                        <stop offset="0.2862" style={{
                            stopColor:"#FFFFFF",
                            stopOpacity:"0"
                            }}/>
                        <stop offset="0.3397" style={{
                            stopColor:"#FFFFF3",
                            stopOpacity:"0.0878"
                            }}/>
                        <stop offset="0.4365" style={{
                            stopColor:"#FFFFD3",
                            stopOpacity:"0.247"
                            }}/>
                        <stop offset="0.5658" style={{
                            stopColor:"#FFFF9F",
                            stopOpacity:"0.4595"
                            }}/>
                        <stop offset="0.7226" style={{
                            stopColor:"#FFFF57",
                            stopOpacity:"0.7174"
                            }}/>
                        <stop offset="0.8945" style={{
                            stopColor:"#FFFF00"
                            }}/>
                    </linearGradient>
                    <polygon id={"Lightbeam_sec_" + this.props.section} style={this.style.st9} points="310.77,318.53 311.71,364.39 6,325 6,148 256,4 350.69,297.2 "/>
                </svg>
            </React.Fragment>
        );
    }
}

export default Projector;