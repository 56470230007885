import React from 'react';

const style = {
    st0: {enableBackground:"new"},
    st1: {fill:"#7E5C44"},
    st2: {fill:"#7D5C44"},
    st3: {fill:"#73543E"},
    st4: {fill:"#76563F"},
    st5: {fill:"#D2946C"},
    st6: {fill:"#DDA177"},
    st7: {fill:"#785841"},
    st8: {fill:"#392F29"},
    st9: {fill:"#56473F"},
    st10: {fill:"#624032"},
    st11: {fill:"#B1735A"},
    st12: {fill:"#2F2723"}
}

const Window = () => (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 363 450">
        <g id="Window">
            <g style={style.st0}>
                <g>
                    <polygon style={style.st1} points="150.98,251.14 159.44,246.22 165.08,260.92 156.62,265.83 			"/>
                </g>
                <g>
                    <polygon style={style.st2} points="136.87,214.41 145.32,209.5 158.02,242.14 149.56,247.06 			"/>
                </g>
                <g>
                    <polygon style={style.st3} points="156.62,265.83 165.08,260.92 190.62,275.66 182.16,280.58 			"/>
                </g>
                <g>
                    <polygon style={style.st1} points="173.71,251.2 182.17,246.28 194.16,277.71 185.7,282.62 			"/>
                </g>
                <g>
                    <polygon style={style.st4} points="163.82,233.24 172.28,228.33 159.44,246.22 150.98,251.14 			"/>
                </g>
                <g>
                    <polygon style={style.st3} points="185.7,282.62 194.16,277.71 206.93,285.08 198.47,289.99 			"/>
                </g>
                <g>
                    <polygon style={style.st4} points="157.56,185.54 166.02,180.62 145.32,209.5 136.87,214.41 			"/>
                </g>
                <polygon style={style.st3} points="182.26,214.49 194.39,197.01 185.93,201.92 173.8,219.41 209.99,240.3 218.44,235.38 		"/>
                <g>
                    <path style={style.st5} d="M329.21,198.63c5.64,3.25,10.19,11.14,10.17,17.61l-0.01,4.27c-0.02,6.47-4.6,9.09-10.24,5.83L69.37,76.37
                        c-5.64-3.25-10.19-11.14-10.17-17.61l0.01-4.27c0.02-6.47,4.6-9.09,10.24-5.83L329.21,198.63z"/>
                </g>
                <g>
                    <path style={style.st6} d="M330.92,193.36c7.83,4.52,14.17,15.49,14.14,24.49l-0.6,212.76c-0.03,9.01-6.4,12.63-14.23,8.11
                        L21.62,260.55c-7.83-4.52-14.17-15.48-14.14-24.49L8.09,23.3c0.03-9,6.4-12.63,14.23-8.11L330.92,193.36z M339.36,220.51
                        l0.01-4.27c0.02-6.47-4.53-14.36-10.17-17.61L69.45,48.65c-5.64-3.25-10.22-0.64-10.24,5.83l-0.01,4.27
                        c-0.02,6.47,4.53,14.36,10.17,17.61l259.76,149.97C334.77,229.6,339.34,226.98,339.36,220.51 M136.87,214.41l12.7,32.65
                        l32.83-47.18l-24.83-14.34L136.87,214.41 M156.62,265.83l25.54,14.74l-18.34-47.33l-12.84,17.89L156.62,265.83 M52.52,55.18
                        l0.01-4.27c0.02-6.48-4.54-14.36-10.17-17.61L23.99,22.69c-5.63-3.25-10.22-0.65-10.24,5.83l-0.01,4.27
                        c-0.02,6.47,4.54,14.36,10.17,17.61l18.37,10.61C47.91,64.26,52.5,61.65,52.52,55.18 M198.47,289.99l12.13-17.5l-36.89-21.3
                        l11.99,31.42L198.47,289.99 M185.93,201.92l-12.13,17.49l36.18,20.89l-11.99-31.42L185.93,201.92"/>
                </g>
                <g>
                    <path style={style.st5} d="M42.36,33.3c5.63,3.25,10.19,11.14,10.17,17.61l-0.01,4.27c-0.02,6.47-4.6,9.08-10.24,5.83L23.91,50.41
                        c-5.63-3.25-10.19-11.14-10.17-17.61l0.01-4.27c0.02-6.48,4.6-9.08,10.24-5.83L42.36,33.3z"/>
                </g>
                <path style={style.st7} d="M352.8,427.55c0.01-0.13,0.03-0.27,0.04-0.4c0.04-0.47,0.07-0.95,0.07-1.45l0.6-212.76
                    c0-0.5-0.02-1.01-0.06-1.52c-0.01-0.13-0.02-0.27-0.03-0.4c-0.04-0.46-0.1-0.92-0.17-1.39c-0.01-0.06-0.01-0.12-0.02-0.18
                    c-0.08-0.52-0.19-1.04-0.31-1.56c-0.03-0.13-0.06-0.27-0.1-0.4c-0.11-0.44-0.22-0.88-0.35-1.33c-0.03-0.1-0.05-0.19-0.08-0.29
                    c-0.16-0.52-0.33-1.04-0.52-1.55c-0.05-0.13-0.1-0.25-0.14-0.38c-0.08-0.2-0.15-0.4-0.23-0.6c-0.1-0.24-0.2-0.47-0.3-0.71
                    c-0.04-0.1-0.08-0.2-0.12-0.29c-0.22-0.51-0.46-1.01-0.71-1.51c-0.06-0.11-0.12-0.23-0.17-0.34c-0.22-0.44-0.46-0.87-0.7-1.3
                    c-0.05-0.08-0.09-0.17-0.14-0.25c-0.28-0.48-0.57-0.96-0.88-1.43c-0.07-0.11-0.15-0.22-0.22-0.33c-0.28-0.42-0.56-0.83-0.85-1.23
                    c-0.05-0.07-0.09-0.13-0.14-0.2c-0.33-0.45-0.67-0.88-1.02-1.31c-0.08-0.1-0.17-0.2-0.26-0.3c-0.33-0.39-0.66-0.76-1-1.13
                    c-0.04-0.04-0.08-0.09-0.12-0.13c-0.37-0.4-0.76-0.77-1.14-1.14c-0.1-0.09-0.19-0.18-0.29-0.27c-0.37-0.35-0.75-0.68-1.14-1
                    c-0.03-0.02-0.05-0.04-0.08-0.06c-0.41-0.33-0.82-0.64-1.24-0.94c-0.1-0.07-0.21-0.14-0.31-0.21c-0.43-0.29-0.86-0.57-1.29-0.82
                    L30.78,10.28C26.83,8,23.26,7.79,20.68,9.29l-8.46,4.91c2.58-1.5,6.15-1.29,10.09,0.99l308.6,178.17
                    c0.44,0.25,0.87,0.53,1.29,0.82c0.1,0.07,0.21,0.14,0.31,0.21c0.42,0.3,0.84,0.61,1.24,0.94c0.03,0.02,0.05,0.04,0.08,0.06
                    c0.39,0.32,0.77,0.65,1.14,1c0.1,0.09,0.19,0.18,0.29,0.27c0.39,0.37,0.77,0.75,1.15,1.14c0.04,0.04,0.08,0.08,0.11,0.13
                    c0.34,0.37,0.68,0.75,1,1.14c0.08,0.1,0.17,0.2,0.25,0.3c0.35,0.43,0.69,0.86,1.02,1.31c0.05,0.06,0.09,0.13,0.14,0.19
                    c0.29,0.4,0.58,0.81,0.86,1.23c0.07,0.11,0.15,0.22,0.22,0.33c0.3,0.47,0.6,0.95,0.88,1.43c0.05,0.08,0.09,0.16,0.14,0.25
                    c0.24,0.43,0.48,0.86,0.7,1.3c0.06,0.11,0.12,0.22,0.17,0.33c0.25,0.5,0.49,1,0.71,1.51c0.04,0.1,0.08,0.19,0.12,0.29
                    c0.19,0.44,0.36,0.87,0.53,1.31c0.05,0.13,0.1,0.25,0.14,0.38c0.19,0.52,0.36,1.04,0.52,1.56c0.03,0.1,0.05,0.19,0.08,0.29
                    c0.13,0.44,0.25,0.89,0.35,1.33c0.03,0.13,0.07,0.27,0.1,0.4c0.12,0.52,0.22,1.04,0.31,1.56c0.01,0.06,0.02,0.12,0.02,0.18
                    c0.07,0.47,0.13,0.93,0.17,1.39c0.01,0.14,0.02,0.27,0.03,0.4c0.04,0.51,0.06,1.02,0.06,1.52l-0.6,212.76
                    c0,0.5-0.03,0.98-0.07,1.45c-0.01,0.14-0.03,0.27-0.04,0.4c-0.04,0.34-0.09,0.68-0.15,1c-0.02,0.12-0.04,0.25-0.07,0.36
                    c-0.09,0.42-0.19,0.83-0.31,1.21c-0.01,0.03-0.02,0.05-0.03,0.07c-0.12,0.36-0.25,0.7-0.4,1.03c-0.03,0.07-0.06,0.15-0.1,0.22
                    c-0.16,0.33-0.33,0.64-0.52,0.94c-0.03,0.05-0.07,0.1-0.11,0.16c-0.18,0.26-0.36,0.51-0.56,0.74c-0.03,0.04-0.07,0.08-0.1,0.12
                    c-0.22,0.25-0.46,0.47-0.71,0.68c-0.05,0.05-0.11,0.09-0.17,0.14c-0.26,0.21-0.53,0.4-0.81,0.56l8.46-4.92
                    c0.28-0.16,0.55-0.36,0.81-0.56c0.06-0.04,0.11-0.09,0.17-0.14c0.25-0.21,0.48-0.44,0.71-0.68c0.04-0.04,0.07-0.08,0.1-0.12
                    c0.2-0.23,0.39-0.48,0.56-0.74c0.04-0.05,0.07-0.1,0.11-0.16c0.19-0.29,0.36-0.61,0.52-0.94c0.03-0.07,0.07-0.14,0.1-0.22
                    c0.15-0.33,0.28-0.67,0.4-1.03c0.01-0.03,0.02-0.05,0.03-0.07c0-0.01,0.01-0.02,0.01-0.03c0.12-0.38,0.22-0.78,0.3-1.19
                    c0.02-0.12,0.04-0.24,0.07-0.36C352.71,428.23,352.76,427.89,352.8,427.55z"/>
            </g>
        </g>
        <g id="Logo_1_">
            <g style={style.st0}>
                <g>
                    <polygon style={style.st8} points="155.74,237.9 164.2,232.99 182.54,280.32 174.08,285.23 			"/>
                </g>
                <g>
                    <polygon style={style.st9} points="155.74,237.9 174.08,285.23 148.54,270.49 142.9,255.8 			"/>
                </g>
                <polygon style={style.st10} points="174.1,250.95 165.64,255.87 202.53,277.17 190.4,294.66 198.86,289.74 210.99,272.25 		"/>
                <g>
                    <polygon style={style.st11} points="202.53,277.17 190.4,294.66 177.63,287.28 165.64,255.87 			"/>
                </g>
                <polygon style={style.st10} points="157.94,185.28 149.48,190.2 174.31,204.53 141.49,251.72 149.95,246.81 182.77,199.62 		"/>
                <g>
                    <polygon style={style.st11} points="174.31,204.53 141.49,251.72 128.79,219.07 149.48,190.2 			"/>
                </g>
                <g>
                    <polygon style={style.st8} points="189.92,213.55 198.38,208.63 210.37,240.05 201.91,244.96 			"/>
                </g>
                <g>
                    <polygon style={style.st9} points="189.92,213.55 201.91,244.96 165.73,224.07 177.86,206.58 			"/>
                </g>
                <g>
                    <polygon style={style.st12} points="177.86,206.58 186.32,201.67 198.38,208.63 189.92,213.55 			"/>
                </g>
            </g>
        </g>
        </svg>
    </React.Fragment>
);

export default Window;