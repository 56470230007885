import React, { Component } from "react";

class FloatingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  style = {
    frame: {
      width: "20vw",
      height: "fit-content",
      position: "relative",
      margin: "3vw",
    },
    img: {
      width: "100%",
      // height: "fit-content",
      transform: "translate(-20px, -20px)",
      borderRadius: "20px",
      position: "relative",
      zIndex: "1",
    },
    backdrop: {
      width: "100%",
      height: "calc(100% - (30px + 0.5vw))",
      position: "absolute",
      backgroundColor: "#5b399e",
      borderRadius: "20px",
      boxShadow: "20px 20px 20px rgba(80, 80, 80, 0.5)",
      top: "0",
      left: "0",
      zIndex: "-1",
      overflowY: "hidden",
      transition: "all ease 200ms",
    },
    backdropHover: {
      width: "calc(100% + 40px)",
      height: "calc(100% + 48px)",
      position: "absolute",
      backgroundColor: "#5b399e",
      borderRadius: "20px",
      boxShadow: "20px 20px 20px rgba(80, 80, 80, 0.5)",
      top: "0",
      left: "0",
      zIndex: "-1",
      overflowY: "hidden",
      transform: "translate(-40px, -40px)",
      transition: "all ease 200ms",
    },
    paragraph: {
      fontFamily: "Quark-Bold",
      fontSize: "calc(30px + 0.5vw)",
      color: "white",
      textAlign: "right",
      marginRight: "0.5em",
      transition: "all ease 300ms",
    },
    paragraphHover: {
      fontFamily: "Quark-Bold",
      fontSize: "calc(30px + 0.5vw)",
      color: "white",
      textAlign: "right",
      marginRight: "0.5em",
      transform: "translateY(1em)",
      opacity: "0",
      transition: "all ease 300ms",
    },
  };

  componentDidMount = () => {};

  mouseHover = () => {
    this.setState({
      hover: !this.state.hover,
    });
  };

  render() {
    return (
      <div
        style={this.style.frame}
        onMouseEnter={this.mouseHover}
        onMouseLeave={this.mouseHover}
        id={this.props.divId}
      >
        <img
          style={this.style.img}
          src={this.props.src}
          alt={this.props.info.alt}
        ></img>
        <div
          style={
            this.state.hover ? this.style.backdropHover : this.style.backdrop
          }
        ></div>

        <p
          style={this.style.paragraph}
        >{`${this.props.info.alt} ${this.props.info.tName}`}</p>
        <p
          style={
            this.state.hover ? this.style.paragraph : this.style.paragraphHover
          }
        >{`น้องรหัส ${this.props.info.junior}`}</p>
      </div>
    );
  }
}

export default FloatingCard;

