import React, { Component } from 'react';

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    style = {
        page: {
          width: "100vw",
          height: this.props.height? this.props.height : "100vh",
          backgroundColor: this.props.bgColor,
          padding: this.props.padding,
          zIndex: "0",
          position: "relative",
          overflow: "hidden"
        },
        background: {
          width: "100vw",
          height: this.props.height? this.props.height : "100vh",
          backgroundImage: "url('" + this.props.image + "')",
          filter: "blur(25px) brightness(0.8)",
          zIndex: "-10",
          position: "absolute",
          top: "0",
          left: "0"
        }
    };

    render() {
      return (
        <section id={this.props.id} style={{position: "relative"}}>
          {this.props.image?
          <section style={this.style.background}></section> : ""  
          }
          <section style={this.style.page}>{this.props.children}</section>
        </section>
      );
    }
}

export default Page;