import React, { Component } from 'react';

class Fountain extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    style = {
        st0: {
            enableBackground: "new"
        },
        st1: {
            fill: "#B3D4D7"
        },
        st2: {
            fill: "#99B6B8"
        },
        st3: {
            fill: "#A7A8A8"
        },
        st4: {
            fill: "#B6B8B8"
        },
        st5: {
            fill: "#C5C7C7"
        },
        st6: {
            fill: "#D5D6D6"
        },
        st7: {
            fill: "#CFD1D1"
        },
        st8: {
            fill: "#C0C2C2"
        },
        st9: {
            fill: "#B1B3B3"
        },
        st10: {
            fill: "#A2A3A3"
        },
        st11: {
            fill: "#939494"
        },
        st12: {
            fill: "#848585"
        },
        st13: {
            fill: "#D5D7D7"
        },
        st14: {
            fill: "#778D8F"
        },
        st15: {
            fill: "#839C9E"
        },
        st16: {
            fill: "#90ABAD"
        },
        st17: {
            fill: "#9DBABD"
        },
        st18: {
            fill: "#AACACC"
        },
        st19: {
            fill: "#B6D9DB"
        },
        st20: {
            fill: "#7F9799"
        },
        st21: {
            fill: "#CCF2F5"
        },
        st22: {
            fill: "#989999"
        },
        st23: {
            fill: "#ACADAD"
        },
        st24: {
            fill: "#BBBDBD"
        },
        st25: {
            fill: "#CACCCC"
        },
        st26: {
            fill: "#DADBDB"
        },
        st27: {
            fill: "#F3F5F5"
        },
        st28: {
            fill: "#898A8A"
        },
        st29: {
            fill: "#8E8F8F"
        },
        st30: {
            fill: "#392F29"
        },
        st31: {
            fill: "#56473F"
        },
        st32: {
            fill: "#624032"
        },
        st33: {
            fill: "#B1735A"
        },
        st34: {
            fill: "#2F2723"
        }
    }

    render() {
        return (
            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                viewBox="0 0 280 223" style={{enableBackground: "new 0 0 280 223"}}>
            <g id="Fountain">
                <g style={this.style.st0}>
                    <g>
                        <polygon style={this.style.st1} points="207.94,99.62 202.27,106.03 139.84,69.99 139.84,60.3 			"/>
                    </g>
                    <g>
                        <polygon style={this.style.st2} points="139.84,60.3 139.84,69.99 77.82,106.03 72.18,99.62 			"/>
                    </g>
                    <g style={this.style.st0}>
                        <g style={this.style.st0}>
                            <path style={this.style.st3} d="M260.03,98.42l-4.02,6.45c0.08,2.26-0.04,4.53-0.35,6.79c-0.32,2.34-0.86,4.68-1.61,7
                                c-0.79,2.43-1.8,4.84-3.06,7.22c-0.85,1.62-1.81,3.22-2.87,4.8l3.75-5.56c1.1-1.64,2.09-3.29,2.97-4.96
                                c1.3-2.46,2.35-4.96,3.16-7.47c0.77-2.4,1.33-4.81,1.67-7.24C260,103.11,260.12,100.76,260.03,98.42z"/>
                            <path style={this.style.st4} d="M259.2,91.54c-0.47-2.28-1.13-4.54-1.99-6.79c-0.87-2.27-1.93-4.53-3.2-6.75l-3.82,7.13
                                c1.22,2.15,2.25,4.33,3.09,6.52c0.83,2.17,1.47,4.36,1.93,6.56c0.46,2.21,0.72,4.43,0.8,6.65l4.02-6.45
                                C259.95,96.12,259.68,93.82,259.2,91.54z"/>
                            <path style={this.style.st5} d="M249.44,71.19c-1.85-2.37-3.93-4.69-6.26-6.95c-2.59-2.52-5.48-4.97-8.67-7.32l-3.17,7.83
                                c3.08,2.28,5.88,4.64,8.38,7.08c2.25,2.19,4.26,4.43,6.05,6.72c1.68,2.16,3.15,4.36,4.42,6.58l3.82-7.13
                                C252.7,75.69,251.17,73.42,249.44,71.19z"/>
                            <path style={this.style.st6} d="M224.64,50.47c-0.78-0.45-1.56-0.89-2.35-1.32c-5.1-2.79-10.52-5.28-16.19-7.45
                                c-6.79-2.61-13.94-4.78-21.32-6.51l-1.51,8.56c7.13,1.67,14.04,3.77,20.61,6.29c5.48,2.11,10.71,4.51,15.64,7.21
                                c0.77,0.42,1.52,0.85,2.28,1.28c3.44,1.99,6.62,4.07,9.54,6.22l3.17-7.83C231.49,54.68,228.19,52.52,224.64,50.47z"/>
                            <path style={this.style.st7} d="M157.88,30.91c-5.67-0.5-11.38-0.77-17.1-0.8c-4-0.02-7.99,0.07-11.97,0.28l0.36,8.72
                                c3.85-0.2,7.71-0.29,11.57-0.27c5.53,0.03,11.05,0.29,16.53,0.77c8.86,0.78,17.59,2.16,26,4.13l1.51-8.56
                                C176.07,33.15,167.04,31.72,157.88,30.91z"/>
                            <path style={this.style.st8} d="M128.81,30.4c-3.64,0.19-7.26,0.48-10.87,0.86c-3.57,0.38-7.12,0.85-10.63,1.42
                                c-3.57,0.58-7.1,1.25-10.58,2.03l1.42,8.57c3.37-0.75,6.78-1.4,10.23-1.96c3.39-0.55,6.82-1.01,10.27-1.37
                                c3.48-0.37,6.99-0.65,10.5-0.83L128.81,30.4z"/>
                            <path style={this.style.st9} d="M96.74,34.7c-3.53,0.78-7.02,1.67-10.43,2.65c-3.42,0.99-6.78,2.07-10.06,3.26
                                c-3.26,1.18-6.45,2.46-9.54,3.85l2.43,8.25c2.99-1.34,6.07-2.58,9.22-3.72c3.17-1.15,6.42-2.2,9.73-3.15
                                c3.3-0.95,6.67-1.81,10.09-2.57L96.74,34.7z"/>
                            <path style={this.style.st10} d="M66.7,44.47c-3.11,1.39-6.12,2.88-9.03,4.46c-0.92,0.5-1.84,1.02-2.74,1.54c-2,1.16-3.91,2.35-5.73,3.57
                                c-2.77,1.85-5.34,3.77-7.72,5.74l3.27,7.74c2.3-1.91,4.78-3.76,7.46-5.54c1.76-1.18,3.61-2.33,5.54-3.45
                                c0.87-0.51,1.75-1,2.65-1.49c2.81-1.54,5.73-2.97,8.73-4.31L66.7,44.47z"/>
                            <path style={this.style.st11} d="M41.48,59.78c-2.55,2.12-4.88,4.3-6.98,6.54c-2.31,2.46-4.33,4.98-6.09,7.55
                                c-2.04,2.99-3.7,6.04-4.99,9.13l3.87,6.96c1.25-2.99,2.86-5.94,4.83-8.83c1.69-2.49,3.66-4.92,5.88-7.3
                                c2.03-2.16,4.27-4.27,6.74-6.32L41.48,59.78z"/>
                            <path style={this.style.st12} d="M25.67,117.08c-1.54-5.27-1.99-10.63-1.35-15.94c0.45-3.76,1.44-7.5,2.98-11.17L23.43,83
                                c-1.59,3.8-2.62,7.67-3.08,11.56c-0.66,5.5-0.19,11.04,1.4,16.49c1.33,4.56,3.45,9.06,6.36,13.43l3.71,5.58
                                C29.01,125.84,26.96,121.49,25.67,117.08z"/>
                        </g>
                    </g>
                    <g>
                        <polygon style={this.style.st13} points="140.24,132.39 140.24,142.07 77.82,106.03 83.49,99.62 			"/>
                    </g>
                    <g>
                        <polygon style={this.style.st4} points="196.63,99.62 202.27,106.03 140.24,142.07 140.24,132.39 			"/>
                    </g>
                    <g style={this.style.st0}>
                        <g style={this.style.st0}>
                            <path style={this.style.st14} d="M254.41,94.98c-1.29-4.41-3.34-8.76-6.15-12.98l-3.71-5.58c2.71,4.08,4.69,8.28,5.93,12.54
                                c1.49,5.09,1.92,10.26,1.31,15.4c-0.43,3.63-1.39,7.24-2.88,10.79l3.87,6.96c1.54-3.68,2.53-7.42,2.98-11.18
                                C256.4,105.6,255.95,100.25,254.41,94.98z"/>
                            <path style={this.style.st15} d="M248.91,115.14c-1.21,2.89-2.76,5.74-4.66,8.52c-1.64,2.4-3.53,4.75-5.68,7.05
                                c-1.96,2.09-4.13,4.12-6.51,6.1l3.27,7.74c2.47-2.05,4.71-4.16,6.74-6.32c2.23-2.38,4.19-4.82,5.88-7.3
                                c1.97-2.89,3.58-5.84,4.83-8.83L248.91,115.14z"/>
                            <path style={this.style.st16} d="M232.06,136.81c-2.22,1.84-4.62,3.63-7.2,5.35c-1.7,1.14-3.49,2.25-5.35,3.33
                                c-0.84,0.49-1.7,0.97-2.56,1.44c-2.72,1.48-5.53,2.87-8.43,4.17l2.43,8.25c3-1.34,5.92-2.78,8.73-4.31
                                c0.89-0.49,1.78-0.98,2.65-1.49c1.93-1.12,3.78-2.27,5.54-3.45c2.68-1.79,5.16-3.64,7.46-5.54L232.06,136.81z"/>
                            <path style={this.style.st17} d="M208.53,151.1c-2.89,1.29-5.86,2.49-8.9,3.59c-3.06,1.11-6.19,2.12-9.39,3.05
                                c-3.19,0.92-6.44,1.75-9.74,2.48l1.42,8.57c3.42-0.76,6.78-1.61,10.09-2.57c3.31-0.95,6.55-2.01,9.72-3.15
                                c3.15-1.14,6.23-2.38,9.22-3.72L208.53,151.1z"/>
                            <path style={this.style.st18} d="M180.5,160.21c-3.25,0.72-6.55,1.35-9.88,1.89c-3.28,0.53-6.59,0.97-9.92,1.33
                                c-3.36,0.36-6.75,0.62-10.14,0.8l0.36,8.72c3.52-0.18,7.02-0.46,10.5-0.83c3.45-0.37,6.88-0.82,10.27-1.37
                                c3.45-0.56,6.86-1.21,10.23-1.96L180.5,160.21z"/>
                            <path style={this.style.st19} d="M150.56,164.23c-3.72,0.19-7.45,0.28-11.17,0.26c-5.34-0.03-10.67-0.28-15.96-0.74
                                c-8.55-0.76-16.98-2.09-25.11-3.99l-1.51,8.56c8.42,1.97,17.15,3.35,26.01,4.13c5.48,0.48,11,0.74,16.53,0.77
                                c3.86,0.02,7.73-0.07,11.57-0.27L150.56,164.23z"/>
                            <path style={this.style.st18} d="M78.42,153.69c-5.29-2.03-10.34-4.35-15.1-6.96c-0.74-0.4-1.47-0.82-2.2-1.24
                                c-3.32-1.92-6.39-3.93-9.22-6.01l-3.17,7.83c2.92,2.16,6.1,4.24,9.55,6.23c0.75,0.43,1.51,0.86,2.28,1.28
                                c4.93,2.7,10.17,5.1,15.65,7.21c6.56,2.52,13.47,4.62,20.6,6.29l1.51-8.56C91.43,158.15,84.76,156.12,78.42,153.69z"/>
                            <path style={this.style.st17} d="M43.81,132.65c-2.17-2.11-4.12-4.28-5.84-6.49c-1.62-2.08-3.05-4.21-4.27-6.36l-3.82,7.13
                                c1.27,2.23,2.74,4.43,4.42,6.58c1.78,2.29,3.8,4.54,6.05,6.72c2.51,2.44,5.3,4.8,8.38,7.08l3.17-7.83
                                C48.93,137.28,46.23,135,43.81,132.65z"/>
                            <path style={this.style.st16} d="M30.72,113.5c-0.8-2.1-1.42-4.21-1.86-6.34c-0.44-2.13-0.7-4.28-0.77-6.42l-4.02,6.45
                                c0.08,2.22,0.35,4.44,0.8,6.65c0.45,2.2,1.1,4.39,1.93,6.56c0.84,2.2,1.87,4.37,3.09,6.52l3.82-7.13
                                C32.52,117.72,31.53,115.62,30.72,113.5z"/>
                            <path style={this.style.st20} d="M31.95,81.39c-1.07,1.58-2.02,3.18-2.87,4.8c-1.25,2.38-2.27,4.79-3.06,7.22c-0.75,2.32-1.29,4.65-1.61,7
                                c-0.31,2.26-0.43,4.52-0.35,6.79l4.02-6.45c-0.08-2.19,0.04-4.37,0.34-6.56c0.31-2.26,0.83-4.52,1.55-6.76
                                c0.76-2.35,1.74-4.68,2.95-6.97c0.82-1.56,1.75-3.1,2.77-4.63L31.95,81.39z"/>
                        </g>
                    </g>
                    <g>
                        <path style={this.style.st21} d="M219,53.75c43.88,25.33,44.11,66.42,0.52,91.75c-43.6,25.34-114.52,25.33-158.39,0
                            c-43.88-25.33-44.12-66.41-0.52-91.75C104.19,28.41,175.12,28.41,219,53.75z M140.28,138.94l67.66-39.32l-68.1-39.32L72.18,99.62
                            L140.28,138.94"/>
                    </g>
                    <g style={this.style.st0}>
                        <g style={this.style.st0}>
                            <path style={this.style.st22} d="M270.18,93.46c-1.47-5.02-3.8-9.97-6.99-14.77l-3.71-5.58c3.1,4.66,5.36,9.46,6.78,14.33
                                c1.7,5.82,2.2,11.73,1.49,17.59c-0.5,4.15-1.59,8.27-3.29,12.33c-1.38,3.3-3.15,6.56-5.33,9.74c-1.87,2.74-4.03,5.43-6.49,8.06
                                l3.5,7.52c2.54-2.7,4.77-5.48,6.7-8.31c2.24-3.28,4.07-6.64,5.49-10.04c1.75-4.18,2.88-8.44,3.39-12.72
                                C272.45,105.55,271.93,99.46,270.18,93.46z"/>
                            <path style={this.style.st23} d="M252.64,135.15c-2.24,2.39-4.72,4.71-7.44,6.97c-2.53,2.1-5.27,4.14-8.23,6.12
                                c-1.95,1.3-3.98,2.57-6.11,3.81c-0.96,0.56-1.94,1.11-2.92,1.65c-3.1,1.69-6.32,3.28-9.63,4.76l2.43,8.25
                                c3.42-1.53,6.73-3.16,9.93-4.91c1.02-0.55,2.02-1.12,3.01-1.7c2.2-1.28,4.3-2.59,6.3-3.93c3.05-2.03,5.87-4.14,8.49-6.31
                                c2.81-2.33,5.36-4.73,7.67-7.19L252.64,135.15z"/>
                            <path style={this.style.st24} d="M218.31,158.45c-3.3,1.47-6.7,2.84-10.18,4.1c-3.5,1.27-7.08,2.43-10.73,3.48
                                c-3.65,1.05-7.36,1.99-11.13,2.83l1.42,8.57c3.89-0.86,7.72-1.84,11.48-2.92c3.77-1.09,7.46-2.28,11.07-3.59
                                c3.59-1.3,7.09-2.71,10.49-4.23L218.31,158.45z"/>
                            <path style={this.style.st25} d="M186.28,168.87c-3.71,0.82-7.48,1.54-11.29,2.16c-3.74,0.61-7.53,1.11-11.34,1.52
                                c-3.84,0.41-7.71,0.71-11.59,0.92l0.36,8.72c4-0.21,7.99-0.52,11.95-0.95c3.93-0.42,7.83-0.94,11.69-1.56
                                c3.93-0.64,7.81-1.38,11.64-2.23L186.28,168.87z"/>
                            <path style={this.style.st26} d="M152.06,173.46c-4.25,0.22-8.51,0.32-12.77,0.3c-6.1-0.03-12.19-0.32-18.24-0.85
                                c-9.77-0.86-19.41-2.38-28.7-4.56l-1.51,8.56c9.58,2.24,19.51,3.81,29.59,4.7c6.23,0.55,12.52,0.84,18.81,0.88
                                c4.4,0.02,8.79-0.08,13.17-0.31L152.06,173.46z"/>
                            <path style={this.style.st25} d="M69.62,161.41c-6.04-2.32-11.82-4.97-17.26-7.95c-0.85-0.46-1.68-0.93-2.51-1.41
                                c-3.8-2.19-7.31-4.49-10.53-6.87l-3.17,7.83c3.33,2.46,6.95,4.82,10.86,7.08c0.85,0.49,1.72,0.98,2.59,1.46
                                c5.61,3.07,11.57,5.8,17.8,8.2c7.47,2.87,15.33,5.26,23.45,7.16l1.51-8.56C84.48,166.51,76.86,164.2,69.62,161.41z"/>
                            <path style={this.style.st24} d="M30.06,137.37c-2.48-2.41-4.71-4.89-6.67-7.42c-1.85-2.38-3.48-4.81-4.88-7.27l-3.82,7.13
                                c1.44,2.54,3.12,5.04,5.03,7.49c2.03,2.61,4.32,5.16,6.88,7.65c2.85,2.77,6.03,5.46,9.54,8.06l3.17-7.83
                                C35.91,142.67,32.83,140.06,30.06,137.37z"/>
                            <path style={this.style.st23} d="M15.1,115.49c-0.92-2.4-1.62-4.81-2.13-7.24c-0.5-2.44-0.8-4.89-0.89-7.34l-4.02,6.45
                                c0.09,2.53,0.39,5.06,0.91,7.57c0.52,2.5,1.25,5,2.19,7.47c0.96,2.5,2.13,4.98,3.52,7.42l3.82-7.13
                                C17.16,120.31,16.02,117.91,15.1,115.49z"/>
                            <path style={this.style.st22} d="M17.05,77.98c-1.21,1.8-2.3,3.62-3.27,5.46c-1.43,2.71-2.58,5.45-3.48,8.22c-0.85,2.64-1.46,5.3-1.83,7.96
                                c-0.36,2.57-0.49,5.15-0.4,7.73l4.02-6.45c-0.09-2.5,0.04-5,0.39-7.49c0.36-2.59,0.95-5.16,1.78-7.72
                                c0.87-2.68,1.99-5.35,3.37-7.97c0.94-1.78,2-3.55,3.17-5.29L17.05,77.98z"/>
                        </g>
                    </g>
                    <g>
                        <path style={this.style.st27} d="M230.27,47.2c50.15,28.95,50.42,75.9,0.6,104.85C181.05,181,99.99,181,49.84,152.05
                            C-0.3,123.1-0.57,76.14,49.25,47.19C99.07,18.24,180.13,18.24,230.27,47.2z M55.48,148.77c47.02,27.14,122.99,27.15,169.71,0
                            c46.71-27.15,46.46-71.15-0.55-98.3c-47.02-27.14-122.99-27.14-169.71,0C8.21,77.62,8.47,121.62,55.48,148.77"/>
                    </g>
                    <g>
                        <polygon style={this.style.st27} points="196.63,99.62 140.24,132.39 83.49,99.62 139.88,66.85 			"/>
                    </g>
                    <path style={this.style.st12} d="M256.36,171.45c0.12-0.15,0.25-0.31,0.37-0.46c0.06-0.08,0.12-0.16,0.18-0.24c0.81-1.05,1.58-2.11,2.3-3.18
                        c1.25-1.85,2.5-3.71,3.75-5.56c-6.39,9.48-16.17,18.36-29.35,26.02c-51.38,29.86-134.96,29.86-186.68,0
                        c-13.59-7.85-23.63-16.98-30.11-26.71l3.71,5.58v0c0,0,0,0,0,0c0.21,0.31,0.43,0.63,0.65,0.94c0.19,0.27,0.37,0.55,0.57,0.82
                        c0.1,0.14,0.2,0.27,0.3,0.41c0.75,1.03,1.54,2.06,2.38,3.07c0.06,0.07,0.13,0.15,0.19,0.22c1.75,2.1,3.68,4.17,5.79,6.19
                        c0.49,0.47,1,0.94,1.52,1.41c0.21,0.19,0.41,0.38,0.63,0.58c0.04,0.03,0.07,0.06,0.11,0.1c1.11,0.99,2.26,1.96,3.46,2.92
                        c0.31,0.25,0.63,0.5,0.94,0.74c2.43,1.9,5.03,3.76,7.82,5.55c0.28,0.18,0.57,0.36,0.85,0.54c1.3,0.82,2.63,1.63,4,2.42
                        c50.14,28.95,131.2,28.95,181.02,0c2.63-1.53,5.12-3.11,7.47-4.74c0.99-0.68,1.95-1.37,2.89-2.07c1.57-1.17,3.08-2.36,4.52-3.57
                        c0.04-0.04,0.08-0.07,0.13-0.11c0.96-0.81,1.89-1.64,2.78-2.47c0.65-0.6,1.28-1.2,1.9-1.81c0.13-0.13,0.27-0.26,0.41-0.4
                        c0.15-0.15,0.3-0.31,0.45-0.46c0.92-0.93,1.81-1.88,2.65-2.83C254.79,173.39,255.59,172.43,256.36,171.45z"/>
                    <g style={this.style.st0}>
                        <g style={this.style.st0}>
                            <path style={this.style.st12} d="M272.04,106.41l-0.08,27.94c-0.06,19.44-12.83,38.85-38.35,53.68c-2.83,1.64-5.76,3.2-8.77,4.66
                                l0.08-27.94c3.01-1.46,5.94-3.02,8.77-4.66C259.21,145.26,271.98,125.85,272.04,106.41"/>
                            <path style={this.style.st28} d="M224.91,164.76l-0.08,27.94c-7.82,3.8-16.22,6.98-25.03,9.56l0.08-27.94
                                C208.69,171.74,217.09,168.55,224.91,164.76"/>
                            <path style={this.style.st29} d="M199.88,174.32l-0.08,27.94c-8.34,2.44-17.03,4.33-25.93,5.68l0.08-27.94
                                C182.85,178.65,191.55,176.76,199.88,174.32"/>
                            <path style={this.style.st11} d="M173.96,180l-0.08,27.94c-10.35,1.57-20.97,2.4-31.6,2.48l0.08-27.94
                                C152.99,182.39,163.61,181.57,173.96,180"/>
                            <path style={this.style.st22} d="M142.36,182.48l-0.08,27.94c-34.41,0.28-69.01-7.18-95.35-22.39c-0.88-0.51-1.75-1.02-2.6-1.54l0.08-27.94
                                c0.85,0.52,1.72,1.03,2.6,1.54C73.34,175.3,107.95,182.76,142.36,182.48"/>
                            <path style={this.style.st11} d="M44.41,158.55l-0.08,27.94c-7.51-4.58-13.87-9.57-19.05-14.84l0.08-27.94
                                C30.54,148.98,36.89,153.97,44.41,158.55"/>
                            <path style={this.style.st29} d="M25.36,143.71l-0.08,27.94c-4.64-4.71-8.34-9.65-11.12-14.72L14.24,129
                                C17.01,134.06,20.72,139,25.36,143.71"/>
                            <path style={this.style.st28} d="M14.24,129l-0.08,27.94c-3.06-5.6-4.99-11.36-5.78-17.16l0.08-27.94C9.24,117.64,11.17,123.4,14.24,129"/>
                            <path style={this.style.st12} d="M8.45,111.84l-0.08,27.94c-0.28-2.06-0.42-4.12-0.41-6.19l0.08-27.94C8.03,107.72,8.17,109.78,8.45,111.84
                                "/>
                        </g>
                    </g>
                </g>
            </g>
            <g id="Logo_2_">
                <g style={this.style.st0}>
                    <g>
                        <polygon style={this.style.st30} points="122.1,58.91 130.56,54 148.9,101.33 140.45,106.24 			"/>
                    </g>
                    <g>
                        <polygon style={this.style.st31} points="122.1,58.91 140.45,106.24 114.91,91.5 109.26,76.81 			"/>
                    </g>
                    <polygon style={this.style.st32} points="140.46,71.96 132,76.88 168.9,98.18 156.77,115.67 165.22,110.75 177.35,93.26 		"/>
                    <g>
                        <polygon style={this.style.st33} points="168.9,98.18 156.77,115.67 144,108.29 132,76.88 			"/>
                    </g>
                    <polygon style={this.style.st32} points="124.3,6.29 115.84,11.21 140.67,25.54 107.86,72.73 116.31,67.82 149.13,20.63 		"/>
                    <g>
                        <polygon style={this.style.st33} points="140.67,25.54 107.86,72.73 95.16,40.08 115.84,11.21 			"/>
                    </g>
                    <g>
                        <polygon style={this.style.st30} points="156.29,34.56 164.74,29.64 176.73,61.06 168.28,65.97 			"/>
                    </g>
                    <g>
                        <polygon style={this.style.st31} points="156.29,34.56 168.28,65.97 132.09,45.08 144.22,27.59 			"/>
                    </g>
                    <g>
                        <polygon style={this.style.st34} points="144.22,27.59 152.68,22.68 164.74,29.64 156.29,34.56 			"/>
                    </g>
                </g>
            </g>
            </svg>
        );
    }
}

export default Fountain;