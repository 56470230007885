import React from 'react';

const style = {
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translate(-50%, -50%)"
};

const TeamHeader = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="875" height="123.001" viewBox="0 0 875 123.001" style={style}>
        <g id="Group_2" data-name="Group 2" transform="translate(-472 -2258)">
            <path id="Subtraction_2" data-name="Subtraction 2" d="M-1276,107h-130v-.153l90-50.347-90-50.347V6h130V107Z" transform="translate(1878 2274)" fill="#cd2020"/>
            <path id="Polygon_3" data-name="Polygon 3" d="M21.75,0,43.5,29H0Z" transform="translate(573 2381) rotate(-90)" fill="#e75151"/>
            <path id="Subtraction_3" data-name="Subtraction 3" d="M130,0H0V.154L90,50.5,0,100.848V101H130V0Z" transform="translate(1347 2381.001) rotate(180)" fill="#cd2020"/>
            <path id="Polygon_5" data-name="Polygon 5" d="M21.75,0,43.5,29H0Z" transform="translate(1246 2337.5) rotate(90)" fill="#e75151"/>
            <rect id="Rectangle_43" data-name="Rectangle 43" width="673" height="101" transform="translate(573 2258)" fill="#ff5252"/>
        </g>
    </svg>    
);

export default TeamHeader;